<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card class="card-congratulations bg-danger  match-height" @click="act_data('')">
          <b-row>
            <b-col md="12">
              <h5>
                <b-avatar size="30" rounded="" variant="light-white">
                  <feather-icon icon="AirplayIcon" size="20"></feather-icon>
                </b-avatar><span class="text-white"> Periode Pendaftaran</span>
              </h5>
            </b-col>

          </b-row>
          <kategori></kategori>
        </b-card>
      </b-col>
      <b-col md="8">

        <b-card>
          <b-row>
            <b-col md="8">
              <h6>
                <b-avatar size="30" rounded="" variant="light-primary">
                  <feather-icon icon="AirplayIcon" size="20"></feather-icon>
                </b-avatar><span class="text-primary"> Dafatr peserta Pelopor Keselamatan Priode 2023 </span>
              </h6>
            </b-col>

            <b-col md="4">
              <b-button block variant="info" size="sm">
                <feather-icon icon="DownloadCloudIcon" size="15"></feather-icon> Download
              </b-button>
            </b-col>
          </b-row>
          <tabelPendafat></tabelPendafat>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BButton
  } from 'bootstrap-vue'
  import kategori from './component/kategori.vue'
  import tabelPendafat from './component/tabel.vue'
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BAvatar,
      kategori,
      FeatherIcon,
      BAvatar,
      BButton,
      tabelPendafat,

    }

    ,
    FeatherIcon
  }
</script>

<style>

</style>