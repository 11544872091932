<template>
    <b-row>
        <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="3" sm="8" class="my-1">

        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(pendaftara)="data">

                    <b-row>
                        <b-col md="4">
                            <small>Nama</small>
                            <h5 class="text-warning">ZULFA KAMILA AZZAHRA</h5>
                            <h6><small>tempat, Tanggal Lahir {{ data.item.id }}</small>
                                <br> <span class="text-primary">Boyolali, 19 November 2005 </span> </h6>
                            <h6><small>Jenis Kelamin</small> <span class="text-warning">Laki-laki </span></h6>
                           <a href="https://wa.me/+6282146731872" target ="_blank">
                            <b-button block variant="warning" size="sm">
                                <feather-icon icon="PhoneCallIcon" size="15"></feather-icon> 82146731872
                            </b-button>
                           </a>
                        </b-col>
                        <b-col md="8">
                            <b-row>
                                <b-col md="8">
                                    <h6><small>Instansi Pengirim</small></h6>
                            <h6>Pendaftaran Pelopor Keselamatan Tahun 2022</h6>
                                </b-col>
                                <b-col md="4">
                                    <b-button block variant="primary" size="sm"  v-b-modal.modal-1>
                                <feather-icon icon="ListIcon" size="15"></feather-icon> Detail
                            </b-button>
                                </b-col>
                            </b-row>
                           
                            <h6><small>SMAN 1 DEMAK , Kelas  XI (11)</small></h6>

                            <h6><small>Jurusan</small></h6>
                            <h6>Ilmu Terapan, Ilmu Formal, Kesehatan, Ilmu Alam</h6>
                           
                        </b-col>
                    </b-row>
                </template>


            </b-table>
        </b-col>

        <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
        <detail-peserta></detail-peserta>
    </b-row>
  
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import DetailPeserta from './detailPeserta.vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            FeatherIcon,
                DetailPeserta,
        },
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'pendaftara',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }],
                items: [{
                        id: 1,
                        kategori_pengaduan: "Korrie O'Crevy",
                    },

                ],

            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>