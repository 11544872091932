<template>
    <b-row>

        <b-col md="12" class="my-1">
            <b-form-group label="" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''" variant="primary">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table   responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(aduan)="data">
                    <b-row class="mlm-5" @click="detail()">
                        <b-col md="3">
                            <b-avatar size="55" rounded="" variant="light-white"><span style="font-size:15px" class="text-white">{{ data.item.id }}</span></b-avatar>
               </b-col>
                        <b-col md="8" class="text-primary">
                            <h6 class="text-white"><small>Kategori </small></h6>
                            <h5 class="text-white">Penerangan jalan</h5>
                        </b-col>

                    </b-row>
                </template>
            </b-table>
        </b-col>

        <b-col cols="6">
            Total Row {{ totalRows }}
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                fields: [{
                    key: 'aduan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }],
                items: [{
                        id: 1,

                    },
                    {
                        id: 2,

                    },
                    {
                        id: 3,
                    },
                    {
                        id: 4,
                    }, {
                        id: 4,
                    },
                    {
                        id: 4,
                    },
                    {
                        id: 4,
                    },
                    {
                        id: 4,
                    },


                ],

            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            detail(){
                alert();
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>